import {CustomerActionTypes, IStoreCustomers, REMOVE_CUSTOMER, SET_CUSTOMER, SET_CUSTOMER_CREDITS, SET_CUSTOMER_ORDERS, SET_CUSTOMERS, UPDATE_CUSTOMER} from "../actions/Customer";

export interface CustomersState {
    customers: IStoreCustomers
}

const initialState: CustomersState = {
    customers: {}
};

export default function (state = initialState, action: CustomerActionTypes): CustomersState {
    switch (action.type) {
        case SET_CUSTOMERS:
            return {
                ...state,
                customers: action.payload.customers
            };
        case SET_CUSTOMER:
            return {
                ...state,
                customers: {
                    ...state.customers,
                    [action.payload.customer._id!]: action.payload.customer
                }
            };
        case SET_CUSTOMER_ORDERS:
            return {
                ...state,
                customers: {
                    ...state.customers,
                    [action.payload.customerId]: {
                        ...state.customers[action.payload.customerId],
                        orders: action.payload.orders
                    }
                }
            };

        case SET_CUSTOMER_CREDITS:

            console.log({
                ...state.customers[action.payload.customerId],
                credits: action.payload.credits
            })

            return {
                ...state,
                customers: {
                    ...state.customers,
                    [action.payload.customerId]: {
                        ...state.customers[action.payload.customerId],
                        credits: action.payload.credits
                    }
                }
            };
        case UPDATE_CUSTOMER:
            if (state.customers.hasOwnProperty(action.payload.customerId))
                return {
                    ...state,
                    customers: {
                        ...state.customers,
                        [action.payload.customerId]: {
                            ...state.customers[action.payload.customerId],
                            ...action.payload.customerData
                        }
                    }
                };
            else {
                return {
                    ...state,
                    customers: {
                        ...state.customers,
                        [action.payload.customerId]: {
                            ...action.payload.customerData
                        }
                    }
                };
            }
        case REMOVE_CUSTOMER:
            const newcustomers = Object.assign({}, state.customers);
            delete newcustomers[action.payload.customerId];
            return {
                ...state,
                customers: newcustomers
            };
        default:
            return state;
    }
}
