import {
    SET_MATERIALS,
    ADD_MATERIAL,
    REMOVE_MATERIAL,
    UPDATE_MATERIAL, IStoreMaterials, MaterialActionTypes
} from "../actions/Material";

export interface MaterialsState {
    materials: IStoreMaterials,
}

const initialState: MaterialsState = {
    materials: {},
};

export default function (state = initialState, action: MaterialActionTypes): MaterialsState {
    switch (action.type) {
        case SET_MATERIALS:
            return {
                ...state,
                materials: action.payload.materials,
            };
        case ADD_MATERIAL:
            return {
                ...state,
                materials: {
                    ...state.materials,
                    [action.payload.material._id!]: {
                        ...action.payload.material
                    }
                }
            };
        case UPDATE_MATERIAL:
            return {
                ...state,
                materials: {
                    ...state.materials,
                    [action.payload.materialId]: {
                        ...state.materials[action.payload.materialId],
                        ...action.payload.material
                    }
                }
            };
        case REMOVE_MATERIAL:
            const newmaterials = Object.assign({}, state.materials);
            delete newmaterials[action.payload.materialId];
            return {
                ...state,
                materials: newmaterials
            };
        default:
            return state;
    }
}
