import { combineReducers } from "redux";
import AppData from "./AppData";
import Company from "./Company";
import Customers from "./Customers";
import Orders from "./Order";
import Materials from "./Material";
import Expenses from "./Expense";
import Products from "./Product";
import Categories from "./Category";
import Designs from "./Design";
import Canvas from "./Canvas";
import CustomersOrders from "./CustomersOrders";
import Api from "./Api";

const mainReducer = combineReducers({
  AppData,
  Company,
  Customers,
  Materials,
  Orders,
  Expenses,
  Products,
  Categories,
  Designs,
  CustomersOrders,
  Canvas,
  Api,
});

export type RootState = ReturnType<typeof mainReducer>;
export default mainReducer;
