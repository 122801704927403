import {CustomerOrderActionTypes, IStoreCustomersOrders, SET_CUSTOMERS_ORDERS} from "../actions/CustomersOrders";

export interface CustomersOrdersState {
    orders: IStoreCustomersOrders;
}

const initialState: CustomersOrdersState = {
    orders: {},
};

export default function (state = initialState, action: CustomerOrderActionTypes): CustomersOrdersState {
    switch (action.type) {
        case SET_CUSTOMERS_ORDERS:
            return {
                ...state,
                orders: action.payload.customersOrders,
            };
        default:
            return state;
    }
}
