import React from "react"
import { Provider } from "react-redux"
import store from "./store"
import { PersistGate } from "redux-persist/integration/react"
import persistor from "./store/persistor"
import App from "./App"
import i18n from "./i18n"
import { I18nextProvider } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import { PopupProvider } from "react-custom-popup"
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles"

const theme = createMuiTheme({
	palette: {
		primary: {
			light: "#39796b",
			main: "#004d40",
			dark: "#00251a",
			contrastText: "#fff",
		},
		secondary: {
			light: "#718792",
			main: "#455a64",
			dark: "#1c313a",
			contrastText: "#ffffff",
		},
		text: {
			primary: "#333333",
			secondary: "#4a4a4a",
		},
	},
	typography: {
		h1: {
			fontSize: 40,
			color: "#1e3333",
			fontFamily: "'Patrick Hand', cursive",
			marginTop: 20,
			marginBottom: 20,
		},
		h2: {
			fontSize: 25,
			color: "#1e3333",
			fontFamily: "'Patrick Hand', cursive",
			marginTop: 20,
			marginBottom: 20,
		},
		h3: {
			fontSize: 20,
			color: "#1e3333",
			fontFamily: "'Patrick Hand', cursive",
			marginTop: 20,
			marginBottom: 20,
		},
		button: {
			fontFamily: "'Oxygen', cursive",
			textTransform: "none",
		},
		body1: {
			fontSize: 20,
			fontFamily: "'Oxygen', cursive",
		},
	},
	overrides: {
		MuiOutlinedInput: {
			root: {
				"&:hover $notchedOutline": {
					borderColor: "#004d40",
				},
				"& $notchedOutline": {
					borderColor: "#8f8f8f",
					borderWidth: "1px",
				},
			},
		},
		MuiInputLabel: {
			root: {
				color: "#687573",
			},
		},
		MuiInputBase: {
			root: {
				fontSize: 20,
				fontFamily: "'Oxygen', cursive",
			},
		},
	},
})

const AppSetup = (props: RouteComponentProps) => {
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<I18nextProvider i18n={i18n}>
					<MuiThemeProvider theme={theme}>
						<PopupProvider>
							<App {...props} history={props.history} match={props.match} />
						</PopupProvider>
					</MuiThemeProvider>
				</I18nextProvider>
			</PersistGate>
		</Provider>
	)
}

export default AppSetup
