import {
    SHOW_LOADING,
    HIDE_LOADING,
    AppDataActionTypes
} from "../actions/AppData";

export interface AppDataState {
    loading: boolean,
};

const initialState: AppDataState = {
    loading: false
};

export default function (state = initialState, action: AppDataActionTypes): AppDataState {
    switch (action.type) {
        case SHOW_LOADING:
            return {
                ...state,
                loading: true
            };
        case HIDE_LOADING:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}