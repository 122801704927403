import {API_REQUEST_FAIL, API_REQUEST_START, API_REQUEST_SUCCESS, ApiActionTypes} from "../actions/Api";
import {ApiResponse} from "../api";

export interface ApiDataState {
    loading: boolean,
    apiCalls: { [key: string]: ApiResponse }
}

const initialState: ApiDataState = {
    loading: false,
    apiCalls: {}
};

export default function (state = initialState, action: ApiActionTypes): ApiDataState {
    switch (action.type) {
        case API_REQUEST_START:
            return {
                ...state,
                loading: true
            };
        case API_REQUEST_SUCCESS:
            return {
                ...state,
                apiCalls:{
                  ...state.apiCalls,
                    [action.payload.datastore]:action.payload.response
                },
                loading: false
            };
        case API_REQUEST_FAIL:
            return {
                ...state,
                apiCalls:{
                    ...state.apiCalls,
                    [action.payload.datastore]:action.payload.response
                },
                loading: false
            };
        default:
            return state;
    }
}