import {IMaterial} from "../models/Material";

export const SET_MATERIALS = "SET_MATERIALS";
export const UPDATE_MATERIAL = "UPDATE_MATERIAL";
export const ADD_MATERIAL = "ADD_MATERIAL";
export const REMOVE_MATERIAL = "REMOVE_MATERIAL";

export interface IStoreMaterials {
  [key: string]: IMaterial
}

interface SetMaterialsAction {
  type: typeof SET_MATERIALS;
  payload: {materials:IStoreMaterials}
}

interface PatchMaterialAction{
  type: typeof UPDATE_MATERIAL;
  payload:{
    materialId:string;
    material: IMaterial;
  }
}

interface NewMaterialAction{
  type: typeof ADD_MATERIAL;
  payload:{
    material: IMaterial;
  }
}

interface RemoveMaterialAction{
  type: typeof REMOVE_MATERIAL;
  payload:{
    materialId: string;
  }
}

export const setMaterials = (materials:IStoreMaterials): MaterialActionTypes => ({
  type: SET_MATERIALS,
  payload: {
    materials
  }
});

export const patchMaterial = (materialId:string, material:IMaterial): MaterialActionTypes => ({
  type: UPDATE_MATERIAL,
  payload: {
    materialId,
    material
  }
});

export const newMaterial = (material:IMaterial): MaterialActionTypes => ({
  type: ADD_MATERIAL,
  payload: {
    material
  }
});

export const removeMaterial = (materialId:string): MaterialActionTypes => ({
  type: REMOVE_MATERIAL,
  payload: {
    materialId
  }
});

export type MaterialActionTypes = SetMaterialsAction | PatchMaterialAction | NewMaterialAction | RemoveMaterialAction;