import { IDesign } from "../models/Design";

export const SET_DESIGNS = "SET_DESIGNS";
export const SET_TEMPLATES = "SET_TEMPLATES";
export const SET_DECK_DESIGNS = "SET_DECK_DESIGNS";
export const UPDATE_DECK_DESIGN = "UPDATE_DECK_DESIGN";
export const UPDATE_DESIGN = "UPDATE_DESIGN";
export const ADD_DESIGN = "ADD_DESIGN";
export const REMOVE_DESIGN = "REMOVE_DESIGN";
export const SET_DISPLAY_URL = "SET_DISPLAY_URL";
export const SET_DESIGN_CATEGORY = "SET_DESIGN_CATEGORY";

export interface IStoreDesigns {
	[key: string]: IDesign;
}

interface SetDesignCategoryAction {
	type: typeof SET_DESIGN_CATEGORY;

	payload: { category: string | null };
}

interface SetDisplayUrl {
	type: typeof SET_DISPLAY_URL;

	payload: { displayUrls: { [key: string]: string } };
}

interface SetDesignsAction {
	type: typeof SET_DESIGNS;

	payload: { designs: IStoreDesigns };
}

interface SetTemplatesAction {
	type: typeof SET_TEMPLATES;

	payload: { templates: IStoreDesigns };
}

interface SetDecksDesignsAction {
	type: typeof SET_DECK_DESIGNS;

	payload: { designs: IStoreDesigns };
}

interface UpdateDeckDesignsAction {
	type: typeof UPDATE_DECK_DESIGN;

	payload: { design: IDesign };
}

interface PatchDesignAction {
	type: typeof UPDATE_DESIGN;

	payload: {
		design: IDesign;
	};
}

interface NewDesignAction {
	type: typeof ADD_DESIGN;

	payload: {
		design: IDesign;
	};
}

interface RemoveDesignAction {
	type: typeof REMOVE_DESIGN;

	payload: {
		designUuid: string;
	};
}

export const setDesigns = (designs: IStoreDesigns): DesignActionTypes => ({
	type: SET_DESIGNS,
	payload: {
		designs,
	},
});

export const setTemplates = (templates: IStoreDesigns): DesignActionTypes => ({
	type: SET_TEMPLATES,
	payload: {
		templates,
	},
});

export const setDisplayUrls = (displayUrls: {
	[key: string]: string;
}): DesignActionTypes => ({
	type: SET_DISPLAY_URL,
	payload: { displayUrls },
});

export const setDesignCategory = (
	category: string | null
): DesignActionTypes => ({
	type: SET_DESIGN_CATEGORY,
	payload: { category },
});

export const patchDesign = (design: IDesign): DesignActionTypes => ({
	type: UPDATE_DESIGN,
	payload: {
		design,
	},
});

export const newDesign = (design: IDesign): DesignActionTypes => ({
	type: ADD_DESIGN,
	payload: {
		design,
	},
});

export const setDecksDesigns = (designs: IStoreDesigns): DesignActionTypes => ({
	type: SET_DECK_DESIGNS,
	payload: {
		designs,
	},
});

export const updateDeckDesign = (design: IDesign): DesignActionTypes => ({
	type: UPDATE_DECK_DESIGN,
	payload: {
		design,
	},
});

export const removeDesign = (designUuid: string): DesignActionTypes => ({
	type: REMOVE_DESIGN,
	payload: {
		designUuid,
	},
});

export type DesignActionTypes =
	| SetDesignsAction
	| SetTemplatesAction
	| SetDesignCategoryAction
	| SetDisplayUrl
	| PatchDesignAction
	| NewDesignAction
	| RemoveDesignAction
	| SetDecksDesignsAction
	| UpdateDeckDesignsAction;
