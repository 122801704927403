import React, {useEffect} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Container,
    Form,
    Row
} from 'reactstrap';
import {useForm} from "react-hook-form";
import FormInput from "../../../components/Basic/FormInput";
import {login, LoginCredentials, OldLoginCredentials} from "../../../api/company";

import '../Register/Register.css';
import {Link, RouteComponentProps} from "react-router-dom";

interface LoginProps extends RouteComponentProps {
}


const Login = (props: LoginProps) => {

    const {register, handleSubmit, setValue, getValues, watch, reset} = useForm<LoginCredentials>(
        {
            defaultValues: {
                admin_username: "",
                admin_password: "",
            }
        }
    );

    const watchInput = watch();

    useEffect(() => {
        register("admin_username");
        register("admin_password");
    }, [register]);

    const doLogin = async (values: LoginCredentials, dispatch: any) => {
        login(values);
    };

    return (
        <div id={'landingBackground'} className="app flex-row">
            <Container fluid className={'animated fadeIn'}>
                <Row className={'align-items-center'} style={{minHeight: '100%'}}>
                    <Col md={'12'} lg={{size: 6, offset: 1}} sm={'12'}>
                        <Row className={'typewriter'}>
                            <Col md={'12'}><h1>Do more</h1></Col>
                            <Col md={'12'}><h1>with less</h1></Col>
                        </Row>
                    </Col>
                    <Col md={'12'} lg={'3'}>
                        <Card className="mx-4">
                            <CardBody className="p-4">
                                <Form>

                                    <Row className={'text-center'}>
                                        <Col md={'12'}>
                                            <h1>Sign in</h1>
                                        </Col>
                                        <Col md={'12'}>
                                            <p className="text-muted">Login to an existing account</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={{size:12}}>
                                            <FormInput
                                                register={register}
                                                value={watchInput.admin_username}
                                                type={"text"}
                                                setValue={setValue}
                                                name="admin_username"
                                                label="Username"
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <FormInput
                                                secret
                                                register={register}
                                                value={watchInput.admin_password}
                                                type={"password"}
                                                setValue={setValue}
                                                name="admin_password"
                                                label="Password"
                                            />
                                        </Col>
                                    </Row>

                                </Form>
                            </CardBody>
                            <CardFooter className="p-4">
                                <Row>
                                    <Col xs="12" sm="12">
                                        <Button onClick={handleSubmit(doLogin)} color="primary" block>Login</Button>
                                    </Col>
                                </Row>
                                {/*<Row style={{marginTop: 20}}>*/}
                                {/*    <Col md={12} className={'text-center'}>*/}
                                {/*        <span>Don't have an account? <Link to={'/register'}>Create</Link> one now</span>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );

}

export default Login;
