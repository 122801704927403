import {ICustomerOrder} from "../models/CustomerOrder";

export const SET_CUSTOMERS_ORDERS = "SET_CUSTOMERS_ORDERS";

export interface IStoreCustomersOrders {
  [key: string]: ICustomerOrder
}

interface SetCustomersOrdersAction {
  type: typeof SET_CUSTOMERS_ORDERS;
  payload: {customersOrders:IStoreCustomersOrders}
}

export const setCustomersOrders = (customersOrders:IStoreCustomersOrders):CustomerOrderActionTypes => ({
  type: SET_CUSTOMERS_ORDERS,
  payload: {
    customersOrders
  }
});

export type CustomerOrderActionTypes = SetCustomersOrdersAction;
