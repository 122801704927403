import {
	ADD_DESIGN,
	DesignActionTypes,
	IStoreDesigns,
	REMOVE_DESIGN,
	SET_DECK_DESIGNS,
	SET_DESIGN_CATEGORY,
	SET_DESIGNS,
	SET_DISPLAY_URL,
	SET_TEMPLATES,
	UPDATE_DECK_DESIGN,
	UPDATE_DESIGN,
} from "../actions/Designs";

export interface CanvasState {
	designs: IStoreDesigns;
	templates: IStoreDesigns;
	category: string | null;
	deckDesign: IStoreDesigns;
	displayUrls: { [key: string]: string };
}

const initialState: CanvasState = {
	designs: {},
	templates: {},
	category: null,
	displayUrls: {},
	deckDesign: {},
};

const DesignReducer = (
	state = initialState,
	action: DesignActionTypes
): CanvasState => {
	switch (action.type) {
		case SET_DISPLAY_URL: {
			return {
				...state,
				displayUrls: {
					...state.displayUrls,
					...action.payload.displayUrls,
				},
			};
		}
		case SET_DESIGN_CATEGORY: {
			return {
				...state,
				category: action.payload.category,
			};
		}
		case SET_DECK_DESIGNS:
			return {
				...state,
				deckDesign: action.payload.designs,
			};
		case UPDATE_DECK_DESIGN:
			return {
				...state,
				deckDesign: {
					...state.deckDesign,
					[action.payload.design.uuid!]: {
						...state.deckDesign[action.payload.design.uuid!],
						...action.payload.design,
					},
				},
			};
		case SET_DESIGNS:
			return {
				...state,
				designs: action.payload.designs,
			};
		case SET_TEMPLATES:
			return {
				...state,
				templates: action.payload.templates,
			};
		case ADD_DESIGN:
			return {
				...state,
				designs: {
					...state.designs,
					[action.payload.design.uuid!]: {
						...action.payload.design,
					},
				},
			};
		case UPDATE_DESIGN:
			return {
				...state,
				designs: {
					...state.designs,
					[action.payload.design.uuid!]: {
						...state.designs[action.payload.design.uuid!],
						...action.payload.design,
					},
				},
			};
		case REMOVE_DESIGN:
			const newDesigns = Object.assign({}, state.designs);
			delete newDesigns[action.payload.designUuid];
			return {
				...state,
				designs: newDesigns,
			};
		default:
			return state;
	}
};

export default DesignReducer;
