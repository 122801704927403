import { ApiResponse, postApi } from "./index"
import { hideLoading, showLoading } from "../actions/AppData"
import { ICompany } from "../models/Company"
import { setCompany } from "../actions/Company"
import UI from "../UI"
import store from "../store"
import { apiRequestFail, apiRequestSuccess } from "../actions/Api"

export interface LoginCredentials {
	admin_username: string
	admin_password: string
}

export interface OldLoginCredentials {
	username: string
	password: string
}

interface LoginResponse extends ApiResponse {
	data: { token: string; company: ICompany }
}

export async function login(
	credentials: LoginCredentials | OldLoginCredentials,
) {
	try {
		store.dispatch(showLoading())
		const response = await postApi("/login", credentials, false)
		const company = (response as LoginResponse).data.company
		company["token"] = (response as LoginResponse).data.token
		company["logged_in"] = true
		store.dispatch(setCompany(company))
		store.dispatch(hideLoading())
	} catch (err) {
		UI.showToast("Invalid Nickname or Password!", "error")
		console.log(err)
	}
}

export async function createCompany(companyData: FormData) {
	try {
		store.dispatch(showLoading())
		await postApi("/company", companyData, true)
		store.dispatch(hideLoading())
		window.location.href = "/login"
		UI.showToast("Company Created!", "success")
	} catch (error) {
		const errorMessage = (error as any).data || error
		store.dispatch(apiRequestFail("create-company", errorMessage))
		UI.showToast("Error creating company", "error")
		console.log(error)
	}
}

export async function requestEditAccess() {
	try {
		store.dispatch(showLoading())
		const result = await postApi("/company/edit-access", {}, true)
		if (result && result.data && result.data.hasOwnProperty("editKey")) {
			const editKey = (result.data as { editKey: string }).editKey
			window.open(
				`http://localhost:8888/${store.getState().Company.name}?editKey=${editKey}`,
				"_blank",
			)
		}
		store.dispatch(hideLoading())
	} catch (error) {
		UI.showToast("Error getting edit access", "error")
		console.log(error)
	}
}

export async function revokeEditAccess() {
	try {
		store.dispatch(showLoading())
		await postApi("/company/revoke-access", {}, true)
		UI.showToast("Access Revoked!", "success")
		store.dispatch(hideLoading())
	} catch (error) {
		UI.showToast("Error getting edit access", "error")
		console.log(error)
	}
}

export async function viewWebsite() {
	try {
		store.dispatch(showLoading())
		window.open(
			`http://localhost:8888/${store.getState().Company.name}`,
			"_blank",
		)
		store.dispatch(hideLoading())
	} catch (error) {
		UI.showToast("Error getting edit access", "error")
		console.log(error)
	}
}
