import {IOrder} from "../models/Order";

export const SET_ORDERS = "SET_ORDERS";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const ADD_ORDER = "ADD_ORDER";
export const REMOVE_ORDER = "REMOVE_ORDER";

export interface IStoreOrders {
  [key: string]: IOrder
}

interface SetOrdersAction {
  type: typeof SET_ORDERS;
  payload: {orders:IStoreOrders}
}

interface PatchOrderAction{
  type: typeof UPDATE_ORDER;
  payload:{
    orderId:string;
    order: Partial<IOrder>;
  }
}

interface NewOrderAction{
  type: typeof ADD_ORDER;
  payload:{
    order: IOrder;
  }
}

interface RemoveOrderAction{
  type: typeof REMOVE_ORDER;
  payload:{
    orderId: string;
  }
}

export const setOrders = (orders:IStoreOrders):OrderActionTypes => ({
  type: SET_ORDERS,
  payload: {
    orders
  }
});

export const patchOrder = (orderId:string, order: Partial<IOrder>):OrderActionTypes => ({
  type: UPDATE_ORDER,
  payload: {
    orderId,
    order
  }
});

export const newOrder = (order:IOrder):OrderActionTypes => ({
  type: ADD_ORDER,
  payload: {
    order
  }
});

export const removeOrder = (orderId:string):OrderActionTypes => ({
  type: REMOVE_ORDER,
  payload: {
    orderId
  }
});

export type OrderActionTypes = SetOrdersAction | PatchOrderAction | NewOrderAction | RemoveOrderAction;
