import {
  ADD_ASSET_CHANGE,
  ADD_MULTIPLE_ASSET_CHANGES,
  CanvasActionTypes,
  CLEAR_ASSET_CHANGES,
  CLEAR_SELECTED_ELEMENTS,
  PASTE_COPIED_SHAPES,
  REMOVE_ASSET_CHANGE,
  RESET_ACTIVE_DESIGN,
  SELECT_ELEMENT,
  SET_ACTIVE_DESIGN,
  SET_ACTIVE_DESIGN_BACKGROUND_COLOR,
  SET_ACTIVE_DESIGN_BACKGROUND_IMAGE,
  SET_ACTIVE_DESIGN_ELEMENTS,
  SET_ACTIVE_DESIGN_THUMBNAIL,
  SET_ACTIVE_MENU_BAR_ACTION,
  SET_ACTIVE_SIZE,
  SET_SHAPES_TO_COPY,
  UPDATE_ACTIVE_DESIGN_ELEMENT,
} from "../actions/Canvas";

import { AssetChange, CanvasElement, IDesign, RectangleProps, } from "../models/Design";
import undoable, { excludeAction } from "redux-undo";
import { CanvasUtils } from "../components/Design/Utils";

export type MenuBarOption =
  | "Templates"
  | "Properties"
  | "Shapes"
  | "Background"
  | "Dimensions";

export interface CanvasState {
  activeDesign: IDesign;
  assetChanges: { [key: string]: AssetChange };
  selectedElements: string[];
  shapesToCopy: CanvasElement[];
  activeMenuBar: MenuBarOption;
}

const initialState: CanvasState = {
  activeDesign: {
    elements: [],
    backgroundColor: "#ffffff",
    size: { width: 200, height: 100 },
  },
  assetChanges: {},
  selectedElements: [],
  shapesToCopy: [],
  activeMenuBar: "Templates",
};

const CanvasReducer = (
  state = initialState,
  action: CanvasActionTypes
): CanvasState => {
  switch (action.type) {
    case SET_ACTIVE_DESIGN:
      return {
        ...state,
        activeDesign: action.payload.design,
      };
    case SET_ACTIVE_MENU_BAR_ACTION:
      return {
        ...state,
        activeMenuBar: action.payload.label,
      };
    case SET_ACTIVE_DESIGN_ELEMENTS:
      return {
        ...state,
        activeDesign: {
          ...state.activeDesign,
          elements: action.payload.elements,
        },
      };
    case UPDATE_ACTIVE_DESIGN_ELEMENT:
      const elementPos = state.activeDesign.elements.findIndex(
        (el) => el.props.id === action.payload.newProps.id
      );
      const newElements = [...state.activeDesign.elements];
      newElements[elementPos].props = action.payload.newProps;
      return {
        ...state,
        activeDesign: {
          ...state.activeDesign,
          elements: newElements,
        },
      };
    case SET_ACTIVE_DESIGN_THUMBNAIL:
      return {
        ...state,
        activeDesign: {
          ...state.activeDesign,
          thumbnail: action.payload.thumbnail,
        },
      };
    case SET_ACTIVE_DESIGN_BACKGROUND_COLOR:
      return {
        ...state,
        activeDesign: {
          ...state.activeDesign,
          backgroundColor: action.payload.backgroundColor,
          backgroundImage: undefined,
        },
      };
    case SET_ACTIVE_DESIGN_BACKGROUND_IMAGE:
      return {
        ...state,
        activeDesign: {
          ...state.activeDesign,
          backgroundImage: action.payload.backgroundImage,
          backgroundColor: undefined,
        },
      };
    case SET_ACTIVE_SIZE:
      return {
        ...state,
        activeDesign: {
          ...state.activeDesign,
          size: action.payload.size,
        },
      };
    case ADD_ASSET_CHANGE: {
      const currentAssetChanges = Object.assign({}, state.assetChanges);
      if (action.payload.path.startsWith("thumbnail")) {
        Object.keys(currentAssetChanges).forEach((assetUuid) => {
          if (assetUuid.startsWith("thumbnail")) {
            delete currentAssetChanges[assetUuid];
          }
        });
        return {
          ...state,
          assetChanges: {
            ...currentAssetChanges,
            [action.payload.path]: action.payload,
          },
        };
      }
      return {
        ...state,
        assetChanges: {
          ...state.assetChanges,
          [action.payload.path]: action.payload,
        },
      };
    }
    case ADD_MULTIPLE_ASSET_CHANGES:
      const mappedAssetChanges: { [key: string]: AssetChange } = {};
      action.payload.assetChanges.map((assetChange) => {
        mappedAssetChanges[assetChange.path] = assetChange;
      });
      return {
        ...state,
        assetChanges: {
          ...state.assetChanges,
          ...mappedAssetChanges,
        },
      };
    case REMOVE_ASSET_CHANGE:
      const currentAssetChanges = Object.assign({}, state.assetChanges);
      delete currentAssetChanges[action.payload.path];
      return {
        ...state,
        assetChanges: currentAssetChanges,
      };
    case CLEAR_ASSET_CHANGES:
      return {
        ...state,
        assetChanges: {},
      };
    case SELECT_ELEMENT:
      return {
        ...state,
        selectedElements: [...action.payload.elements],
        activeMenuBar: "Properties",
      };
    case SET_SHAPES_TO_COPY:
      return {
        ...state,
        shapesToCopy: action.payload.shapesToCopy,
      };
    case PASTE_COPIED_SHAPES:
      const newShapes: CanvasElement[] = state.shapesToCopy.map((s) => {
        return {
          ...s,
          props: {
            ...s.props,
            id: CanvasUtils.getRandomCanvasElementId(),
            x: (s.props as RectangleProps).x + 5,
            y: (s.props as RectangleProps).y + 5,
          },
        };
      });
      return {
        ...state,
        activeDesign: {
          ...state.activeDesign,
          elements: [...state.activeDesign.elements, ...newShapes],
        },
      };
    case CLEAR_SELECTED_ELEMENTS:
      return {
        ...state,
        selectedElements: [],
      };
    case RESET_ACTIVE_DESIGN:
      return {
        ...state,
        activeDesign: {
          elements: [],
          backgroundColor: "#ffffff",
          size: {
            width: 200,
            height: 100,
          },
        },
        assetChanges: {},
      };
    default:
      return state;
  }
};

export default undoable(CanvasReducer, {
  limit: 10,
  // debug: true,
  filter: excludeAction([
    SET_ACTIVE_DESIGN,
    SET_ACTIVE_MENU_BAR_ACTION,
    RESET_ACTIVE_DESIGN,
    SET_SHAPES_TO_COPY,
    SELECT_ELEMENT,
    CLEAR_SELECTED_ELEMENTS,
    SET_ACTIVE_DESIGN_THUMBNAIL,
    CLEAR_ASSET_CHANGES,
  ]),
  ignoreInitialState: true,
  syncFilter: true,
});
