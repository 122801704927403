const envVarApiUrl = process.env.REACT_APP_API_URL

export const getApiHost = () => {
	return envVarApiUrl!
}

export const deck = {
	suits: ["S", "D", "C", "H"],
	values: ["A", "2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K"],
}

export const GOOGLE_API_FONTS_KEY = "AIzaSyA30FNhC72sFJ0GNuEhKOAY38R62RhpQZ4"
