import {
    SET_EXPENSES,
    ADD_EXPENSE,
    REMOVE_EXPENSE,
    UPDATE_EXPENSE, IStoreExpenses, ExpenseActionTypes
} from "../actions/Expense";

export interface ExpensesState {
    expenses: IStoreExpenses,
};

const initialState: ExpensesState = {
    expenses: {},
};

export default function (state = initialState, action: ExpenseActionTypes): ExpensesState {
    switch (action.type) {
        case SET_EXPENSES:
            return {
                ...state,
                expenses: action.payload.expenses,
            };
        case ADD_EXPENSE:
            return {
                ...state,
                expenses: {
                    ...state.expenses,
                    [action.payload.expense._id!]: {
                        ...action.payload.expense
                    }
                }
            };
        case UPDATE_EXPENSE:
            return {
                ...state,
                expenses: {
                    ...state.expenses,
                    [action.payload.expenseId]: {
                        ...state.expenses[action.payload.expenseId],
                        ...action.payload.expense
                    }
                }
            };
        case REMOVE_EXPENSE:
            const newexpenses = Object.assign({}, state.expenses);
            delete newexpenses[action.payload.expenseId];
            return {
                ...state,
                expenses: newexpenses
            };
        default:
            return state;
    }
}
