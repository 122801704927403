import {ICompany} from "../models/Company";

export const SET_COMPANY = "SET_COMPANY";
export const LOGOUT_COMPANY = "LOGOUT_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const RESET_COMPANY = "RESET_COMPANY";

interface SetCompanyAction {
    type: typeof SET_COMPANY,
    payload: {
        companyData: ICompany
    }
}

interface ResetCompanyAction {
    type: typeof RESET_COMPANY,
}

interface LogoutCompanyAction {
    type: typeof LOGOUT_COMPANY,
}

interface UpdateCompanyAction {
    type: typeof UPDATE_COMPANY,
    payload: { companyData: ICompany }
}

export const setCompany = (companyData: ICompany): CompanyActionTypes => ({
    type: SET_COMPANY,
    payload: {companyData}
});

export const resetCompany = (): CompanyActionTypes => ({
    type: RESET_COMPANY
});

export const logoutCompany = (): CompanyActionTypes => ({
    type: LOGOUT_COMPANY
})

export const updateCompany = (companyData: ICompany): CompanyActionTypes => ({
    type: UPDATE_COMPANY,
    payload: {
        companyData
    }
});

export type CompanyActionTypes = SetCompanyAction | ResetCompanyAction | LogoutCompanyAction | UpdateCompanyAction;