import {
    SET_ORDERS,
    ADD_ORDER,
    REMOVE_ORDER,
    UPDATE_ORDER, IStoreOrders, OrderActionTypes
} from "../actions/Order";

export interface OrdersState {
    orders: IStoreOrders;
}

const initialState: OrdersState = {
    orders: {},
};

export default function (state = initialState, action: OrderActionTypes): OrdersState {
    switch (action.type) {
        case SET_ORDERS:
            return {
                ...state,
                orders: action.payload.orders,
            };
        case ADD_ORDER:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    [action.payload.order._id!]: {
                        ...action.payload.order
                    }
                }
            };
        case UPDATE_ORDER:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    [action.payload.orderId]: {
                        ...state.orders[action.payload.orderId],
                        ...action.payload.order
                    }
                }
            };
        case REMOVE_ORDER:
            const newOrders = Object.assign({}, state.orders);
            delete newOrders[action.payload.orderId];
            return {
                ...state,
                orders: newOrders
            };
        default:
            return state;
    }
}
