import {
    SET_CATEGORIES,
    ADD_CATEGORY,
    REMOVE_CATEGORY,
    UPDATE_CATEGORY,
    IStoreCategories,
    CategoryActionTypes
} from "../actions/Category";

export interface CategoriesState {
    categories: IStoreCategories
}

const initialState: CategoriesState = {
    categories: {}
};

export default function (state = initialState, action: CategoryActionTypes): CategoriesState {
    switch (action.type) {
        case SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload.categories
            };
        case ADD_CATEGORY:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    [action.payload.category._id]: {
                        ...action.payload.category
                    }
                }
            };
        case UPDATE_CATEGORY:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    [action.payload.categoryId]: {
                        ...state.categories[action.payload.categoryId],
                        ...action.payload.category
                    }
                }
            };
        case REMOVE_CATEGORY:
            const newcategories = Object.assign({}, state.categories);
            delete newcategories[action.payload.categoryId];
            return {
                ...state,
                categories: newcategories
            };
        default:
            return state;
    }
}
