import {
    SET_PRODUCTS,
    ADD_PRODUCTS,
    REMOVE_PRODUCT,
    UPDATE_PRODUCT, IStoreProducts, ProductActionTypes
} from "../actions/Product";

export interface ProductsState {
    products: IStoreProducts,
};

const initialState: ProductsState = {
    products: {},
};

export default function (state = initialState, action: ProductActionTypes): ProductsState {
    switch (action.type) {
        case SET_PRODUCTS:
            return {
                ...state,
                products: action.payload.products
            };
        case ADD_PRODUCTS:
            return {
                ...state,
                products: {
                    ...state.products,
                    ...action.payload.products
                }
            };
        case UPDATE_PRODUCT:
            return {
                ...state,
                products: {
                    ...state.products,
                    [action.payload.productId]: {
                        ...state.products[action.payload.productId],
                        ...action.payload.product
                    }
                }
            };
        case REMOVE_PRODUCT:
            const newproducts = Object.assign({}, state.products);
            delete newproducts[action.payload.productId];
            return {
                ...state,
                products: newproducts
            };
        default:
            return state;
    }
}
