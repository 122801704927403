import {ICategory} from "../models/Category";

export const SET_CATEGORIES = "SET_CATEGORIES";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const REMOVE_CATEGORY = "REMOVE_CATEGORY";

export interface IStoreCategories {
  [key: string]: ICategory
}

interface SetCategoriesAction {
  type: typeof SET_CATEGORIES;
  payload: {categories:IStoreCategories}
}

interface PatchCategoryAction{
  type: typeof UPDATE_CATEGORY;
  payload:{
    categoryId:string;
    category: ICategory;
  }
}

interface NewCategoryAction{
  type: typeof ADD_CATEGORY;
  payload:{
    category: ICategory;
  }
}

interface RemoveCategoryAction{
  type: typeof REMOVE_CATEGORY;
  payload:{
    categoryId: string;
  }
}

export const setCategories = (categories:IStoreCategories): CategoryActionTypes => ({
  type: SET_CATEGORIES,
  payload: {
    categories
  }
});

export const patchCategory = (categoryId:string, category: ICategory): CategoryActionTypes => ({
  type: UPDATE_CATEGORY,
  payload: {
    categoryId,
    category
  }
});

export const newCategory = (category: ICategory): CategoryActionTypes => ({
  type: ADD_CATEGORY,
  payload: {
    category
  }
});

export const removeCategory = (categoryId:string): CategoryActionTypes => ({
  type: REMOVE_CATEGORY,
  payload: {
    categoryId
  }
});

export type CategoryActionTypes = SetCategoriesAction | PatchCategoryAction | NewCategoryAction | RemoveCategoryAction;
