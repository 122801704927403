import {createStore, compose, applyMiddleware} from "redux";
import {persistReducer} from "redux-persist";
import mainReducer from "../reducers";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import thunkMiddleware from 'redux-thunk';

const persistConfig = {
    key: "root",
    storage: storage,
    whitelist: ["Company","Designs"],
    stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, mainReducer);
// @ts-ignore
const store = createStore(persistedReducer, compose(applyMiddleware(thunkMiddleware)));

export default store;
