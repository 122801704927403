import Axios from "axios"
import store from "../store"
import { resetCompany } from "../actions/Company"
import { hideLoading } from "../actions/AppData"
import { OutgoingHttpHeaders } from "http"
import { getApiHost } from "../config"

export interface ApiResponse {
	data: { [key: string]: any } | Array<{ [key: string]: any }>
	success: boolean
	message: string
}

export async function postApi(
	path: string,
	data: object | FormData,
	authorized: boolean = false,
	multipart = false,
): Promise<ApiResponse> {
	try {
		const options = {}
		const headers: OutgoingHttpHeaders = {}
		headers["Content-Type"] = "application/json"
		if (multipart) headers["Content-Type"] = "multipart/form-data"
		if (authorized)
			headers["Authorization"] = "Bearer " + store.getState().Company.token
		// @ts-ignore
		options["headers"] = headers
		const response = await Axios.post(getApiHost() + path, data, options)
		return response.data
	} catch (error) {
		handleError(error)
		// @ts-ignore
		throw error.response
	}
}

export async function putApi(
	path: string,
	data: object,
	authorized: boolean = false,
) {
	try {
		const options = {}
		const headers: OutgoingHttpHeaders = {}
		headers["Content-Type"] = "application/json"
		if (authorized)
			headers["Authorization"] = "Bearer " + store.getState().Company.token
		// @ts-ignore
		options["headers"] = headers
		const response = await Axios.put(getApiHost() + path, data, options)
		return response.data
	} catch (error) {
		handleError(error)
		throw error
	}
}

export async function getApi(
	path: string,
	authorized: boolean = false,
	data: Record<string, any> = {},
): Promise<ApiResponse> {
	try {
		const options = {}
		const headers: OutgoingHttpHeaders = {}
		headers["Content-Type"] = "application/json"
		// @ts-ignore
		options["headers"] = headers
		if (authorized) {
			headers["Authorization"] = "Bearer " + store.getState().Company.token
		}
		const response = await Axios.get(getApiHost() + path, {
			params: data,
			headers: headers,
		})
		return response.data
	} catch (error) {
		handleError(error)
		throw error
	}
}

export async function deleteApi(
	path: string,
	authorized: boolean = false,
	data: { [key: string]: any } = {},
) {
	try {
		const options = {}
		const headers: OutgoingHttpHeaders = {}
		headers["Content-Type"] = "application/json"
		// @ts-ignore
		options["headers"] = headers

		if (authorized)
			headers["Authorization"] = "Bearer " + store.getState().Company.token

		const response = await Axios.delete(getApiHost() + path, {
			params: data,
			headers: headers,
		})
		return response.data
	} catch (error) {
		handleError(error)
		throw error
	}
}

function handleError(error: any) {
	store.dispatch(hideLoading())
	if (error.response) {
		if (error.response.status === 403) {
			store.dispatch(hideLoading())
			store.dispatch(resetCompany())
		}
	}
}
