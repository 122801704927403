import {IProduct} from "../models/Product";

export const SET_PRODUCTS = "SET_PRODUCTS";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const ADD_PRODUCTS = "ADD_PRODUCTS";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";

export interface IStoreProducts {
    [key: string]: IProduct
}

interface SetProductsAction {
    type: typeof SET_PRODUCTS;
    payload: { products: IStoreProducts }
}

interface PatchProductAction {
    type: typeof UPDATE_PRODUCT;
    payload: {
        productId: string;
        product: IProduct;
    }
}

interface NewProductsAction {
    type: typeof ADD_PRODUCTS;
    payload: {
        products: IStoreProducts;
    }
}

interface RemoveProductAction {
    type: typeof REMOVE_PRODUCT;
    payload: {
        productId: string;
    }
}

export const setProducts = (products: IStoreProducts): ProductActionTypes => ({
    type: SET_PRODUCTS,
    payload: {
        products
    }
});

export const patchProduct = (productId: any, product: IProduct): ProductActionTypes => ({
    type: UPDATE_PRODUCT,
    payload: {
        productId,
        product
    }
});

export const newProducts = (products: IStoreProducts): ProductActionTypes => ({
    type: ADD_PRODUCTS,
    payload: {
        products
    }
});

export const removeProduct = (productId: string): ProductActionTypes => ({
    type: REMOVE_PRODUCT,
    payload: { productId }
});

export type ProductActionTypes = SetProductsAction | PatchProductAction | NewProductsAction | RemoveProductAction;
