import {ApiResponse} from "../api";

export const API_REQUEST_START = "API_REQUEST_START";
export const API_REQUEST_SUCCESS = "API_REQUEST_SUCCESS";
export const API_REQUEST_FAIL = "API_REQUEST_FAIL";

interface ApiRequestStartAction {
    type: typeof API_REQUEST_START;
    payload: { datastore: string };
}

interface ApiRequestSuccessAction {
    type: typeof API_REQUEST_SUCCESS;
    payload: { datastore: string, response: ApiResponse };
}

interface ApiRequestFailAction {
    type: typeof API_REQUEST_FAIL;
    payload: { datastore: string, response: ApiResponse };
}

export const apiRequestStart = (datastore: string): ApiActionTypes => ({
    type: API_REQUEST_START,
    payload: {datastore}
});

export const apiRequestSuccess = (datastore: string, response: ApiResponse): ApiActionTypes => ({
    type: API_REQUEST_SUCCESS,
    payload: {datastore, response}
});

export const apiRequestFail = (datastore: string, response: ApiResponse): ApiActionTypes => ({
    type: API_REQUEST_FAIL,
    payload: {datastore, response}
});

export type ApiActionTypes = ApiRequestFailAction | ApiRequestStartAction | ApiRequestSuccessAction;