import {deleteApi, getApi, postApi} from "./index";
import {AddedAssetChange, AssetChange, IDesign} from "../models/Design";
import axios from "axios";
import store from "../store";
import {apiRequestSuccess} from "../actions/Api";
import {hideLoading, showLoading} from "../actions/AppData";
import {newDesign, patchDesign, removeDesign, setDesigns, setDisplayUrls} from "../actions/Designs";
import {clearAssetChanges, setActiveDesign} from "../actions/Canvas";
import UI from "../UI";
import {ICustomerOrder} from "../models/CustomerOrder";
import {setCustomersOrders} from "../actions/CustomersOrders";

export async function createDesign(design: IDesign) {
	try {
		store.dispatch(showLoading());

		const assetsChanges = store.getState().Canvas.present.assetChanges;
		const category = store.getState().Designs.category;

		design.isTemplate = true;
		design.category = category!;
		console.log(design)

		const {preSignedUrls, processKey} = await startDesignSave(
			design,
			assetsChanges
		);

		for (const imageUuid of Object.keys(preSignedUrls)) {
			if (assetsChanges.hasOwnProperty(imageUuid)) {
				const fileToUpload = assetsChanges[imageUuid] as AddedAssetChange;
				await axios.put(preSignedUrls[imageUuid], fileToUpload.file, {
					headers: {
						"Content-Type": "",
					},
				});
			}
		}

		const {design: createdDesign, displayUrls} = await finishDesignSave(processKey);

		if (design.uuid) {
			store.dispatch(patchDesign(createdDesign));
		} else {
			store.dispatch(newDesign(createdDesign));
		}

		store.dispatch(setActiveDesign(createdDesign));
		store.dispatch(clearAssetChanges());

		store.dispatch(setDisplayUrls(displayUrls))
		store.dispatch(hideLoading());
	} catch (err) {
		console.log(err);
	}
}

export async function startDesignSave(
	design: IDesign,
	assetChanges: { [key: string]: AssetChange }
): Promise<{ preSignedUrls: { [key: string]: string }; processKey: string }> {
	const response = await postApi(
		"/design/start",
		{design, assets_changes: assetChanges},
		true
	);
	return response.data as {
		preSignedUrls: { [key: string]: string };
		processKey: string;
	};
}

export async function finishDesignSave(processKey: string) {
	const response = await postApi("/design/finish", {processKey}, true);
	return response.data as { design: IDesign, displayUrls: { [key: string]: string } };
}

export async function getDeckImages() {
	try {
		const response = await getApi("/design/deck/images", true);
		store.dispatch(apiRequestSuccess("deck-images", response));
		return response.data as { [key: string]: string };
	} catch (err) {
		return {};
	}
}

export async function deleteDesign(designUuid: string) {
	try {
		store.dispatch(showLoading());
		await deleteApi("/design/delete", true, {design_uuid: designUuid});
		store.dispatch(removeDesign(designUuid));
	} catch (err) {
		UI.showToast("Error deleting design", "error");
		return {};
	} finally {
		store.dispatch(hideLoading());
	}
}

export async function getTemplates() {
	try {
		const response = await getApi("/design/templates", true);
		const { displayUrls, templates } = response.data as {templates: IDesign[],displayUrls: {[key:string]:string}}
		const mappedDesigns:{[key:string]: IDesign} = {};
		templates.forEach(template=>{
			mappedDesigns[template.uuid!] = template;
		})
		store.dispatch(setDesigns(mappedDesigns));
		store.dispatch(setDisplayUrls(displayUrls))
		return response.data as { [key: string]: string };
	} catch (err) {
		return {};
	}
}

export async function getDesignsCustomersOrders() {
	try {
		const response = await getApi("/design/orders/all", true);
		const { customersOrders } = response.data as {customersOrders: ICustomerOrder[]}
		const mappedCustomersOrders:{[key:string]: ICustomerOrder} = {};
		customersOrders.forEach(customerOrder=>{
			mappedCustomersOrders[customerOrder.uuid] = customerOrder;
		})
		store.dispatch(setCustomersOrders(mappedCustomersOrders));
	} catch (err) {
		store.dispatch(hideLoading());
		console.log(err)
	}
}

