import {toast, TypeOptions} from "react-toastify";

function showToast(text: string, type: TypeOptions) {
    toast(text, {
        type: type,
        draggable:true,
    });
}

function hideToast() {
    toast.dismiss();
}

export default {
    showToast,
    hideToast,
};
