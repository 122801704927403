export interface IDesign {
	uuid?: string;

	elements: Array<CanvasElement>;

	backgroundColor?: string | null;

	backgroundImage?: AssetDetails;

	thumbnail?: AssetDetails;

	creatorUser?: string;

	isTemplate?: boolean;

	category?: string;

	size: {
		width: number;
		height: number;
	};

	createdAt?: string;
	updatedAt?: string;
}

export interface AssetDetails {
	path: string;
}

export interface CanvasElement {
	props: ShapeProps;

	type: "circle" | "rectangle" | "text" | "line" | "image" | "group" | "path";
}

export interface GroupElement extends CanvasElement {
	type: "group";

	props: GroupProps;

	children: CanvasElement[];
}

export type ShapeProps =
	| CircleProps
	| RectangleProps
	| LineProps
	| ImageProps
	| TextProps
	| PathProps
	| GroupProps;

export interface BaseShapeProps {
	x: number;

	y: number;

	fill?: string;

	stroke?: {
		color: string;
		width: number;
	};

	shadow?: {
		color: string;
		opacity: number;
		blur: number;
	}

	rotationDeg: number;

	id: string;

	cornerRadius: number;

	opacity?: number;

}

export interface PathProps extends BaseShapeProps {
	data: string;
	scaleX: number;
	scaleY: number;
}

export interface CircleProps extends BaseShapeProps {
	radiusX: number;

	radiusY: number;
}

export interface TextProps extends BaseShapeProps {
	fontSize: number;

	fontFamily: string;

	width: number;

	height: number;

	text: string;
}

export interface RectangleProps extends BaseShapeProps {
	width: number;

	height: number;
}

export interface LineProps extends BaseShapeProps {
	points: number[];
}

export interface ImageProps extends BaseShapeProps, AssetDetails {
	opacity?: number;
	width?: number;

	height?: number;
}

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export interface GroupProps extends PartialBy<BaseShapeProps, "x" | "y"> {
	scaleX?: number;

	scaleY?: number;

	childrenAbsolutePositions?: {
		[id: string]: { x: number; y: number };
	};
}

export type AssetChange = AddedAssetChange | DeletedAssetChange;

export interface BasicAssetChange {
	path: string;
}

export interface AddedAssetChange extends BasicAssetChange {
	action: SaveDesignAssetsActionType.ADD;
	file: File;
}

export interface DeletedAssetChange extends BasicAssetChange {
	action: SaveDesignAssetsActionType.DELETE;
}

export enum SaveDesignAssetsActionType {
	ADD = "ADD",
	DELETE = "DELETE",
}
