import {Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row} from "reactstrap";
import React, {ChangeEvent, Fragment, useEffect, useState} from "react";
import Select from 'react-select';

import './FormInput.css';
import {DynamicObject} from "../../models/DynamicObject";
import {usePopup} from "react-custom-popup";

const FormInput = (props: any) => {

    let {options} = props;

    const {
        leftIconClass,
        rightIconClass,
        disabled,
        name,
        label,
        textarea,
        error,
        multipleImage,
        type,
        placeholder,
        onChange,
        select_name,
        select_value,
        value,
        setValue,
        rightAction,
        readonly = false
    } = props;

    const [visible, setVisible] = useState(false);
    const [selectOptions, setSelectOptions] = useState<{ label: string, value: any }[] | null>(null);
    const [defaultValue, setDefaultValue] = useState<{ label: string, value: any } | null>(null);
    const {showInputDialog} = usePopup();

    const formatSelectOptions = () => {
        return (options as Array<DynamicObject>).map(option => {
            return {label: option[select_name], value: option[select_value]}
        });
    }

    const selectImage = () => {
        showInputDialog({
            title: 'Choose Image', input: {
                inputType: 'image', multiple: multipleImage || false, name: 'myimage'
            }, onConfirm: (result: DynamicObject | undefined) => {
                if (result) {
                    const {myimage} = result as { myimage: Array<{ src: string, value: File }> };
                    if (multipleImage) {
                        setValue(name, myimage)
                    } else {
                        setValue(name, myimage[0])
                    }
                }
            }
        })
    }

    useEffect(() => {
        if (options) {
            const optionsToShow = formatSelectOptions();
            setSelectOptions(optionsToShow);

            if (value) {
                if (value.hasOwnProperty('label') && value.hasOwnProperty('value')) {
                    setDefaultValue(value);
                } else {
                    const defaultOption = optionsToShow.find(o => o.value === value);
                    if (defaultOption) {
                        setDefaultValue(defaultOption);
                    }
                }
            }
        }
    }, [options, value])

    return (
        <FormGroup>
            <Row>
                <Col>
                    <Label htmlFor="textarea-input">{label}</Label>
                </Col>
            </Row>
            <Row>
                <Col md={"12"}>
                    <InputGroup>
                        {leftIconClass && (
                            <InputGroupAddon addonType={'prepend'}
                            ><InputGroupText><i
                                className={leftIconClass}/></InputGroupText></InputGroupAddon>
                        )}
                        {type === 'image' && (
                            <Button onClick={selectImage}>Choose Image</Button>
                        )}
                        {['select', 'image'].indexOf(type) === -1 && (
                            <Input
                                disabled={disabled}
                                className={`${error && 'error-input'}`}
                                readOnly={readonly}
                                value={value}
                                checked={type === "checkbox" ? value : null}
                                key="text"
                                onChange={(val: ChangeEvent<HTMLInputElement>) => {
                                    if (onChange) { // @ts-ignore
                                        onChange(val.nativeEvent.target.value);
                                    }
                                    if (setValue) { // @ts-ignore
                                        setValue(name, val.nativeEvent.target.value, {shouldValidate: true});
                                    }
                                    if (type === "checkbox")
                                        if (value) setValue(name, false, {shouldValidate: true});
                                        else setValue(name, true, {shouldValidate: true});
                                }}
                                type={type == 'password' && visible ? 'text' : type}
                                rows="5"
                                placeholder={placeholder}
                            >
                            </Input>)}
                        {type === 'select' && selectOptions && (
                            <div style={{display: 'flex', flex: 1}}>
                                <Select styles={{
                                    container: () => {
                                        return {display: 'flex', flex: 1}
                                    }
                                }} options={selectOptions} value={defaultValue}
                                        onChange={(val) => {
                                            if (onChange) { // @ts-ignore
                                                onChange(val);
                                            }
                                            if (setValue) { // @ts-ignore
                                                setValue(name, val, {shouldValidate: true});
                                            }
                                        }}>
                                </Select>
                            </div>
                        )}
                        {rightIconClass && (
                            <InputGroupAddon addonType={'append'}
                            ><InputGroupText><i
                                className={rightIconClass}/></InputGroupText></InputGroupAddon>
                        )}
                        {type === 'password' && (
                            <InputGroupAddon style={{cursor: 'pointer'}} onClick={() => setVisible(!visible)}
                                             addonType={'append'}
                            ><InputGroupText><i
                                className={`fa fa-eye${visible ? '' : '-slash'}`}/></InputGroupText></InputGroupAddon>
                        )}
                    </InputGroup>
                    {error && (<div className={'error-message'}>{error}</div>)}
                </Col>
                {rightAction ? <Col md={1}>{rightAction()}</Col> : null}
            </Row>
        </FormGroup>
    );
};

export default FormInput;
