import {ICustomer} from "../models/Customer";
import {IOrder} from "../models/Order";
import {ICredit} from "../models/Credit";

export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const SET_CUSTOMER_ORDERS = "SET_CUSTOMER_ORDERS";
export const SET_CUSTOMER_CREDITS = "SET_CUSTOMER_CREDITS";
export const REMOVE_CUSTOMER = "REMOVE_CUSTOMER";
export const SET_CUSTOMER = "SET_CUSTOMER";

export interface IStoreCustomers {
    [key: string]: ICustomer
}

interface SetCustomerAction {
    type: typeof SET_CUSTOMER;
    payload: { customer: ICustomer }
}

interface SetCustomersAction {
    type: typeof SET_CUSTOMERS;
    payload: {
        customers: IStoreCustomers
    }
}

interface UpdateCustomerAction {
    type: typeof UPDATE_CUSTOMER;
    payload: {
        customerId: string;
        customerData: ICustomer;
    }
}

interface SetCustomerOrdersAction {
    type: typeof SET_CUSTOMER_ORDERS;
    payload: { customerId: string; orders: Array<IOrder> }
}

interface SetCustomerCreditsAction {
    type: typeof SET_CUSTOMER_CREDITS;
    payload: {
        customerId: string,
        credits: ICredit[]
    }
}

interface RemoveCustomerAction {
    type: typeof REMOVE_CUSTOMER;
    payload: { customerId: string };
}

export const setCustomers = (customers: IStoreCustomers): CustomerActionTypes => ({
    type: SET_CUSTOMERS,
    payload: {
        customers
    }
});

export const setCustomer = (customer: ICustomer): CustomerActionTypes => ({
    type: SET_CUSTOMER,
    payload: {
        customer
    }
});

export const updateCustomer = (customerId: string, customerData: ICustomer): CustomerActionTypes => ({
    type: UPDATE_CUSTOMER,
    payload: {
        customerId,
        customerData
    }
});

export const setCustomerOrders = (customerId: string, orders: Array<IOrder>): CustomerActionTypes => ({
    type: SET_CUSTOMER_ORDERS,
    payload: {
        customerId,
        orders
    }
});

export const setCustomerCredits = (customerId: string, credits:ICredit[]): CustomerActionTypes => ({
    type: SET_CUSTOMER_CREDITS,
    payload: {
        customerId: customerId,
        credits
    }
});

export const removeCustomer = (customerId: string): CustomerActionTypes => ({
    type: REMOVE_CUSTOMER,
    payload: {customerId}
});

export type CustomerActionTypes =
    SetCustomerAction
    | SetCustomersAction
    | UpdateCustomerAction
    | SetCustomerOrdersAction
    | SetCustomerCreditsAction
    | RemoveCustomerAction;
