import {
	AssetDetails,
	CanvasElement,
	GroupElement,
	IDesign,
	ImageProps,
	RectangleProps,
	SaveDesignAssetsActionType,
	TextProps,
} from "../../models/Design"
import React from "react"
import store from "../../store"
import {
	addAssetChange,
	pasteCopiedShapes,
	removeAssetChange,
	setShapesToCopy,
} from "../../actions/Canvas"
import { Stage } from "konva/types/Stage"
import { PopupType } from "react-custom-popup"
import {
	dataURItoFile,
	getFileExtension,
	mmToPixels,
	urlToFileObject,
} from "../../utils"
import * as uuid from "uuid"
import { createDesign } from "../../api/design"

export interface MenuOption {
	label?: string
	onClick?: any
	subMenu?: MenuOption[]
	renderOption?: () => JSX.Element
}

export class CanvasUtils {
	public static getRandomCanvasElementId() {
		return this.randomString()
	}

	public static generateImage(
		// @ts-ignore
		stage: Stage,
		design: IDesign,
	): { file: File; imageSrc: string } {
		const uri = stage.getStage().toDataURL({
			mimeType: "image/png",
			width: mmToPixels(design.size.width),
			height: mmToPixels(design.size.height),
			quality: 1,
			pixelRatio: 2,
		})
		return { imageSrc: uri, file: dataURItoFile(uri) }
	}

	public static saveDesign = async (
		saveType: "create" | "clone" | "update",
		stage: Stage,
	) => {
		const { activeDesign } = store.getState().Canvas.present

		const { category: activeCategory, displayUrls } = store.getState().Designs

		if (activeCategory === null) {
			// PopupActions.showToast({
			// 	type: PopupType.DANGER,
			// 	text: "Choose Category",
			// })
			return
		}

		const thumbnailImage = CanvasUtils.generateImage(stage, activeDesign)

		if (thumbnailImage !== null) {
			const thumbUuid = uuid.v4()
			const thumbName = `thumbnail-${thumbUuid}.png`

			const thumbDetails: AssetDetails = {
				path: thumbName,
			}

			const thumbFile = await urlToFileObject(thumbnailImage.imageSrc)
			store.dispatch(
				addAssetChange({
					file: thumbFile,
					path: thumbName,
					action: SaveDesignAssetsActionType.ADD,
				}),
			)

			const design: IDesign = {
				...activeDesign,
				thumbnail: thumbDetails,
			}

			if (saveType === "clone") {
				design.uuid = undefined
				for (const element of design.elements) {
					if (element.type === "image") {
						const displayUrl = displayUrls[(element.props as ImageProps).path]
						if (displayUrl) {
							const randomAssetUuid = uuid.v4()
							const asset = await urlToFileObject(displayUrl)
							const assetExtensions = getFileExtension(
								(element.props as ImageProps).path,
							)
							store.dispatch(
								addAssetChange({
									file: asset,
									path: `${randomAssetUuid}.${assetExtensions}`,
									action: SaveDesignAssetsActionType.ADD,
								}),
							)
						}
					}
				}
				if (design.backgroundImage) {
					const backgroundUuid = uuid.v4()
					const displayUrl = displayUrls[design.backgroundImage.path]
					const asset = await urlToFileObject(displayUrl)
					const extension = getFileExtension(displayUrl)
					const backrgroundName = `background-${backgroundUuid}.${extension}`

					design.backgroundImage = {
						path: backrgroundName,
					}

					store.dispatch(
						addAssetChange({
							file: asset,
							path: backrgroundName,
							action: SaveDesignAssetsActionType.ADD,
						}),
					)
				}

				const assetChanges = Object.assign(
					{},
					store.getState().Canvas.present.assetChanges,
				)

				Object.values(assetChanges)
					.filter(
						(assetChange) =>
							assetChange.action === SaveDesignAssetsActionType.DELETE,
					)
					.forEach((assetChange) => {
						store.dispatch(removeAssetChange(assetChange.path))
					})
			}

			createDesign(design)
		}
	}

	private static randomString(
		length: number = 32,
		chars: string = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
	) {
		let result = ""
		for (let i = length; i > 0; --i)
			result += chars[Math.floor(Math.random() * chars.length)]
		return result
	}

	public static getContextMenuOptions(
		selectedShapes: string[] | null,
		setSelectedShapes: (shapeIds: string[]) => void,
		deleteShape: (ids: string[]) => void,
		setShapes: (shapes: CanvasElement[]) => void,
		closeMenu: () => void,
	): MenuOption[] {
		const shapes = store.getState().Canvas.present.activeDesign.elements

		const canUngroup =
			selectedShapes !== null &&
			selectedShapes.length === 1 &&
			shapes.find((s) => s.props.id === selectedShapes[0])?.type === "group"

		let selectedElements: CanvasElement[] = shapes.filter(
			(s) => selectedShapes && selectedShapes.includes(s.props.id),
		)

		if (selectedElements.length === 0) {
			const copiedElements = store.getState().Canvas.present.shapesToCopy

			return [
				...(copiedElements ?
					[
						{
							label: "Paste",
							onClick: () => {
								store.dispatch(pasteCopiedShapes())
								closeMenu()
							},
						},
					]
				:	[]),
			]
		}

		const copyShapes = (shapes: string[]) => {
			const shapesCopy = store.getState().Canvas.present.activeDesign.elements
			const elementsToCopy = shapesCopy.filter((s) =>
				shapes.includes(s.props.id),
			)
			store.dispatch(setShapesToCopy(elementsToCopy))
		}

		const options: MenuOption[] = [
			{
				label: "Delete",
				onClick: () => {
					if (selectedShapes) {
						deleteShape(selectedShapes)
					}
					closeMenu()
				},
			},
			{
				label: "Copy",
				onClick: () => {
					if (selectedShapes) {
						copyShapes(selectedShapes)
					}
					closeMenu()
				},
			},
			{
				label: "Send To Back",
				onClick: () => {
					if (selectedShapes) {
						const index = shapes.findIndex(
							(s) => s.props.id === selectedShapes[0],
						)
						setShapes([
							shapes[index],
							...shapes.slice(0, index),
							...shapes.slice(index + 1),
						])
						closeMenu()
					}
				},
			},
			{
				label: "Bring To Front",
				onClick: () => {
					if (selectedShapes) {
						const index = shapes.findIndex(
							(s) => s.props.id === selectedShapes[0],
						)
						setShapes([
							...shapes.slice(0, index),
							...shapes.slice(index + 1),
							shapes[index],
						])
						closeMenu()
					}
				},
			},
			...(selectedShapes && selectedShapes.length > 1 ?
				[
					{
						label: "Group",
						onClick: () => {
							const shapesToGroup = store
								.getState()
								.Canvas.present.activeDesign.elements.filter((s) =>
									selectedShapes.includes(s.props.id),
								)
							const group: GroupElement = {
								children: shapesToGroup,
								props: {
									id: CanvasUtils.getRandomCanvasElementId(),
									rotationDeg: 0,
									cornerRadius: 0,
								},
								type: "group",
							}
							const shapesNotInGroup = shapes.filter(
								(s) => !selectedShapes.includes(s.props.id),
							)
							setShapes([...shapesNotInGroup, group])
							setSelectedShapes([group.props.id])
						},
					},
				]
			:	[]),
			...(canUngroup ?
				[
					{
						label: "Ungroup",
						onClick: () => {
							if (selectedShapes) {
								const groupIndex = shapes.findIndex(
									(s) => s.props.id === selectedShapes[0],
								)

								const group = shapes[groupIndex] as GroupElement

								const newShapes: CanvasElement[] = []

								for (const child of group.children) {
									const newChild: CanvasElement = {
										type: child.type,
										props: {
											...child.props,
										},
									}

									if (
										"width" in newChild.props &&
										group.props?.childrenAbsolutePositions
									) {
										newChild.props.width = Math.max(
											5,
											newChild.props.width! * (group.props.scaleX ?? 1),
										)
									}

									if (
										"height" in newChild.props &&
										group.props?.childrenAbsolutePositions
									) {
										newChild.props.height = Math.max(
											(newChild.props as RectangleProps).height *
												(group.props.scaleY ?? 1),
										)
									}

									if (
										"x" in newChild.props &&
										group.props?.childrenAbsolutePositions
									) {
										newChild.props.x =
											group.props.childrenAbsolutePositions[newChild.props.id].x
									}

									if (
										"y" in newChild.props &&
										group.props?.childrenAbsolutePositions
									) {
										newChild.props.y =
											group.props.childrenAbsolutePositions[newChild.props.id].y
									}

									if (
										"fontSize" in newChild.props &&
										group.props?.childrenAbsolutePositions
									) {
										newChild.props.fontSize = Math.max(
											(newChild.props as TextProps).fontSize *
												(group.props.scaleY ?? 1),
										)
									}

									newShapes.push(newChild)
								}

								if (groupIndex !== -1) {
									setShapes([
										...shapes.slice(0, groupIndex),
										...shapes.slice(groupIndex + 1),
										...newShapes,
									])
								}

								setSelectedShapes(newShapes.map((s) => s.props.id))
							}
						},
					},
				]
			:	[]),
		]

		return options
	}
}
