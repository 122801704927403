import {
  AssetChange,
  AssetDetails,
  CanvasElement,
  IDesign,
  ShapeProps,
} from "../models/Design";
import { MenuBarOption } from "../reducers/Canvas";

export const SET_ACTIVE_DESIGN_ELEMENTS = "SET_ACTIVE_DESIGN_ELEMENTS";
export const UPDATE_ACTIVE_DESIGN_ELEMENT = "UPDATE_ACTIVE_DESIGN_ELEMENT";
export const SET_ACTIVE_SIZE = "SET_ACTIVE_SIZE";
export const SET_ACTIVE_DESIGN_BACKGROUND_COLOR =
  "SET_ACTIVE_DESIGN_BACKGROUND_COLOR";
export const SET_ACTIVE_DESIGN_BACKGROUND_IMAGE =
  "SET_ACTIVE_DESIGN_BACKGROUND_IMAGE";
export const SET_ACTIVE_DESIGN_THUMBNAIL = "SET_ACTIVE_DESIGN_THUMBNAIL";
export const ADD_ACTIVE_DESIGN_ELEMENT = "ADD_ACTIVE_DESIGN_ELEMENT";
export const SET_SHAPES_TO_COPY = "SET_SHAPES_TO_COPY";
export const PASTE_COPIED_SHAPES = "PASTE_COPIED_SHAPES";
export const REMOVE_ACTIVE_DESIGN_ELEMENT = "REMOVE_ACTIVE_DESIGN_ELEMENT";
export const SET_ACTIVE_DESIGN = "SET_ACTIVE_DESIGN";
export const UNSET_ACTIVE_DESIGN = "UNSET_ACTIVE_DESIGN";
export const RESET_ACTIVE_DESIGN = "RESET_ACTIVE_DESIGN";
export const SELECT_ELEMENT = "SELECT_ELEMENT";
export const CLEAR_SELECTED_ELEMENTS = "CLEAR_SELECTED_ELEMENTS";
export const ADD_ASSET_CHANGE = "ADD_ASSET_CHANGE";
export const REMOVE_ASSET_CHANGE = "REMOVE_ASSET_CHANGE";
export const ADD_MULTIPLE_ASSET_CHANGES = "ADD_MULTIPLE_ASSET_CHANGES";
export const CLEAR_ASSET_CHANGES = "CLEAR_ASSET_CHANGES";
export const SET_ACTIVE_MENU_BAR_ACTION = "SET_ACTIVE_MENU_BAR_ACTION";

export interface IStoreDesigns {
  [key: string]: IDesign;
}

interface SetActiveDesignAction {
  type: typeof SET_ACTIVE_DESIGN;

  payload: { design: IDesign };
}

interface SetActiveMenuBarAction {
  type: typeof SET_ACTIVE_MENU_BAR_ACTION;

  payload: { label: MenuBarOption };
}

interface ClearAssetChangesAction {
  type: typeof CLEAR_ASSET_CHANGES;

  payload: {};
}

interface AddAssetChangeAction {
  type: typeof ADD_ASSET_CHANGE;

  payload: AssetChange;
}

interface RemoveAssetChangeAction {
  type: typeof REMOVE_ASSET_CHANGE;

  payload: { path: string };
}

interface AddMultipleAssetChangesAction {
  type: typeof ADD_MULTIPLE_ASSET_CHANGES;

  payload: { assetChanges: AssetChange[] };
}

interface SetActiveDesignBackgroundColorAction {
  type: typeof SET_ACTIVE_DESIGN_BACKGROUND_COLOR;

  payload: { backgroundColor: string };
}

interface SetActiveDesignSize {
  type: typeof SET_ACTIVE_SIZE;

  payload: { size: { width: number; height: number } };
}

interface SetActiveDesignBackgroundImageAction {
  type: typeof SET_ACTIVE_DESIGN_BACKGROUND_IMAGE;

  payload: { backgroundImage: AssetDetails };
}

interface SetActiveDesignThumbnail {
  type: typeof SET_ACTIVE_DESIGN_THUMBNAIL;

  payload: { thumbnail: AssetDetails };
}

interface SetShapesToCopyAction {
  type: typeof SET_SHAPES_TO_COPY;

  payload: { shapesToCopy: CanvasElement[] };
}

interface PasteCopiedShapesAction {
  type: typeof PASTE_COPIED_SHAPES;

  payload: {};
}

interface SelectElementAction {
  type: typeof SELECT_ELEMENT;

  payload: { elements: string[] };
}

interface ClearSelectedElementsAction {
  type: typeof CLEAR_SELECTED_ELEMENTS;

  payload: {};
}

interface SetActiveDesignElementsAction {
  type: typeof SET_ACTIVE_DESIGN_ELEMENTS;

  payload: { elements: Array<CanvasElement> };
}

interface UpdateActiveDesignElementAction {
  type: typeof UPDATE_ACTIVE_DESIGN_ELEMENT;

  payload: { newProps: ShapeProps };
}

interface AddActiveDesignElementsAction {
  type: typeof ADD_ACTIVE_DESIGN_ELEMENT;

  payload: { element: CanvasElement };
}

interface RemoveActiveDesignElementsAction {
  type: typeof REMOVE_ACTIVE_DESIGN_ELEMENT;

  payload: { elementId: string };
}

interface UnsetActiveDesignAction {
  type: typeof UNSET_ACTIVE_DESIGN;

  payload: {};
}

interface ResetActiveDesignAction {
  type: typeof RESET_ACTIVE_DESIGN;

  payload: {};
}

export const updateActiveDesignElement = (
  elementProps: ShapeProps
): CanvasActionTypes => ({
  type: UPDATE_ACTIVE_DESIGN_ELEMENT,
  payload: {
    newProps: elementProps,
  },
});

export const setActiveMenuBar = (menu: MenuBarOption): CanvasActionTypes => ({
  type: SET_ACTIVE_MENU_BAR_ACTION,
  payload: {
    label: menu,
  },
});

export const clearAssetChanges = (): CanvasActionTypes => ({
  type: CLEAR_ASSET_CHANGES,
  payload: {},
});

export const setActiveDesignBackgroundImage = (
  backgroundImage: AssetDetails
): CanvasActionTypes => ({
  type: SET_ACTIVE_DESIGN_BACKGROUND_IMAGE,
  payload: {
    backgroundImage,
  },
});

export const addAssetChange = (
  assetChange: AssetChange
): CanvasActionTypes => ({
  type: ADD_ASSET_CHANGE,
  payload: assetChange,
});

export const removeAssetChange = (path: string): CanvasActionTypes => ({
  type: REMOVE_ASSET_CHANGE,
  payload: { path },
});

export const addMultipleAssetChanges = (
  assetChanges: AssetChange[]
): CanvasActionTypes => ({
  type: ADD_MULTIPLE_ASSET_CHANGES,
  payload: { assetChanges: assetChanges },
});

export const setActiveDesignBackgroundColor = (
  backgroundColor: string
): CanvasActionTypes => ({
  type: SET_ACTIVE_DESIGN_BACKGROUND_COLOR,
  payload: {
    backgroundColor,
  },
});

export const setActiveDesignThumbnail = (
  thumbnail: AssetDetails
): CanvasActionTypes => ({
  type: SET_ACTIVE_DESIGN_THUMBNAIL,
  payload: {
    thumbnail,
  },
});

export const setActiveDesignSize = (size: {
  width: number;
  height: number;
}): CanvasActionTypes => ({
  type: SET_ACTIVE_SIZE,
  payload: {
    size,
  },
});

export const setActiveDesign = (design: IDesign): CanvasActionTypes => ({
  type: SET_ACTIVE_DESIGN,
  payload: {
    design,
  },
});

export const selectElements = (elements: string[]): CanvasActionTypes => ({
  type: SELECT_ELEMENT,
  payload: {
    elements,
  },
});

export const setShapesToCopy = (
  shapesToCopy: CanvasElement[]
): CanvasActionTypes => ({
  type: SET_SHAPES_TO_COPY,
  payload: {
    shapesToCopy,
  },
});

export const pasteCopiedShapes = (): CanvasActionTypes => ({
  type: PASTE_COPIED_SHAPES,
  payload: {},
});

export const clearSelectedElements = (): CanvasActionTypes => ({
  type: CLEAR_SELECTED_ELEMENTS,
  payload: {},
});

// export const addActiveDesignElement = (
//   element: CanvasElement
// ): CanvasActionTypes => ({
//   type: ADD_ACTIVE_DESIGN_ELEMENT,
//   payload: {
//     element,
//   },
// });
//
// export const removeActiveDesignElement = (
//   elementId: string
// ): CanvasActionTypes => ({
//   type: REMOVE_ACTIVE_DESIGN_ELEMENT,
//   payload: {
//     elementId,
//   },
// });

export const setActiveDesignElements = (
  elements: Array<CanvasElement>
): CanvasActionTypes => ({
  type: SET_ACTIVE_DESIGN_ELEMENTS,
  payload: {
    elements,
  },
});

export const unsetActiveDesign = (): CanvasActionTypes => ({
  type: UNSET_ACTIVE_DESIGN,
  payload: {},
});

export const resetActiveDesign = (): CanvasActionTypes => ({
  type: RESET_ACTIVE_DESIGN,
  payload: {},
});

export type CanvasActionTypes =
  | SetActiveMenuBarAction
  | SetActiveDesignBackgroundColorAction
  | SetActiveDesignSize
  | ClearAssetChangesAction
  | SetActiveDesignBackgroundImageAction
  | SetActiveDesignThumbnail
  | AddAssetChangeAction
  | UpdateActiveDesignElementAction
  | RemoveAssetChangeAction
  | AddMultipleAssetChangesAction
  | SelectElementAction
  | SetShapesToCopyAction
  | PasteCopiedShapesAction
  | ClearSelectedElementsAction
  | SetActiveDesignAction
  | SetActiveDesignElementsAction
  | AddActiveDesignElementsAction
  | RemoveActiveDesignElementsAction
  | UnsetActiveDesignAction
  | ResetActiveDesignAction;
