import { useEffect, useRef } from "react";
import Canvg from "canvg";

export const getNestedObjectElement = (keysArray: Array<string>, object: any): any => {
  if (keysArray.length == 1)
    if (object.hasOwnProperty(keysArray[0])) {
      return object[keysArray[0]];
    } else return "";
  if (
    object.hasOwnProperty(keysArray[0]) &&
    typeof object[keysArray[0]] === "object"
  ) {
    const nextKey = keysArray[0];
    const nextArray = keysArray.slice(1, keysArray.length);
    return getNestedObjectElement(nextArray, object[nextKey]);
  } else return "";
};

export function invertHex(hex:string) {
  return (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase()
}

export function useTraceUpdate(props: any) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        // @ts-ignore
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log("Changed props:", changedProps);
    }
    prev.current = props;
  });
}

function dataURItoBlob(dataURI: string): Blob {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  //Old Code
  //write the ArrayBuffer to a blob, and you're done
  //var bb = new BlobBuilder();
  //bb.append(ab);
  //return bb.getBlob(mimeString);

  //New Code
  return new Blob([ab], { type: mimeString });
}

export function dataURItoFile(dataURI: string): File {
  const blob = dataURItoBlob(dataURI);
  const resultFile = new File([blob], "thumbnail.png", {
    type: "image/png",
    lastModified: Date.now(),
  });

  return resultFile;
}


export const getFileExtension = (filename: string): string => {
  const fileParts = filename.split(".");

  if (fileParts.length === 1) {
    return "";
  }

  return fileParts[fileParts.length - 1];
};

export const urlToFileObject = async (imageUrl: string): Promise<File> => {
  const response = await fetch(imageUrl);
  // here image is url/location of image
  const blob = await response.blob();
  return new File([blob], "image." + response.headers.get("Content-Type"), {
    type: blob.type,
  });
};

export const mmToPixels = (mm: number): number => {
  return (mm / 10) * 37.7952755906;
};

// download svg file and get its content as string
export async function urlToString(url: string) {
  const req = await fetch(url);
  const svgString = await req.text();
  return svgString;
}

// parse svg string into DOM
export function parseSVG(svgString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, "image/svg+xml");
  return doc;
}

// get color of element
// we can also check styles of element and other properties like "stroke"
export function getElementColor(el: Element) {
  return el.getAttribute("fill");
}

// find all colors used in svg
export function getColors(svgString: string) {
  const doc = parseSVG(svgString);
  const elements = doc.getElementsByTagName("*");
  const usedColors = [];
  // @ts-ignore
  for (const element of elements) {
    const color = getElementColor(element);
    // if color is defined and uniq we will add it
    if (color && usedColors.indexOf(color) === -1) {
      usedColors.push(color);
    }
  }
  return usedColors;
}

// replace colors in svg string
// map is an objects with "oldColor: newColor" values
export function replaceColors(
    svgString: string,
    map: { [key: string]: string }
) {
  // we can do some RegExp magic here
  // but I will just manually check every element
  const doc = parseSVG(svgString);
  const elements = doc.getElementsByTagName("*");
  // @ts-ignore
  for (const element of elements) {
    const color = getElementColor(element);
    // @ts-ignore
    if (map[color]) {
      // @ts-ignore
      element.setAttribute("fill", map[color]);
    }
  }
  // serialize DOM back into string
  const xmlSerializer = new XMLSerializer();
  const str = xmlSerializer.serializeToString(doc);
  return str;
}

export async function getSvgDataUrl(svgString: string) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (ctx) {
    const v = await Canvg.fromString(ctx, svgString);
    await v.render();
  }

  return canvas.toDataURL();
}

export function getSvgPath(svgString: string) {
  const doc = parseSVG(svgString);
  const svgPaths = (doc.getElementsByTagName(
      "path"
  ) as unknown) as HTMLElement[];

  let stringPath: string = "";

  for (const path of svgPaths) {
    stringPath = stringPath + path.getAttribute("d") + ",";
  }

  return stringPath;
}
