import {IExpense} from "../models/Expense";

export const SET_EXPENSES = "SET_EXPENSES";
export const UPDATE_EXPENSE = "UPDATE_EXPENSE";
export const ADD_EXPENSE = "ADD_EXPENSE";
export const REMOVE_EXPENSE = "REMOVE_EXPENSE";

export interface IStoreExpenses {
    [key: string]: IExpense
}

interface SetExpensesAction {
    type: typeof SET_EXPENSES;
    payload: { expenses: IStoreExpenses }
}

interface PatchExpenseAction {
    type: typeof UPDATE_EXPENSE;
    payload: {
        expenseId: string;
        expense: IExpense;
    }
}

interface NewExpenseAction {
    type: typeof ADD_EXPENSE;
    payload: {
        expense: IExpense;
    }
}

interface RemoveExpenseAction {
    type: typeof REMOVE_EXPENSE;
    payload: {
        expenseId: string;
    }
}

export const setExpenses = (expenses: IStoreExpenses): ExpenseActionTypes => ({
    type: SET_EXPENSES,
    payload: {
        expenses
    }
});

export const patchExpense = (expenseId: any, expense: IExpense): ExpenseActionTypes => ({
    type: UPDATE_EXPENSE,
    payload: {
        expenseId,
        expense
    }
});

export const newExpense = (expense: IExpense): ExpenseActionTypes => ({
    type: ADD_EXPENSE,
    payload: {
        expense
    }
});

export const removeExpense = (expenseId: string): ExpenseActionTypes => ({
    type: REMOVE_EXPENSE,
    payload: {
        expenseId,
    }
});

export type ExpenseActionTypes = SetExpensesAction | PatchExpenseAction | NewExpenseAction | RemoveExpenseAction;

