import {CompanyActionTypes, LOGOUT_COMPANY, RESET_COMPANY, SET_COMPANY, UPDATE_COMPANY,} from "../actions/Company";
import {ICompany} from "../models/Company";

export interface CompanyState extends ICompany {
    logged_in: boolean,
    token: string,
}

const initialState: CompanyState = {
    _id: "",
    path: "",
    logged_in: false,
    admin_username: "",
    email: "",
    company_logo: "",
    description: "",
    token: "",
    name: "",
    phone: "",
    city: ""
};

export default function (state = initialState, action: CompanyActionTypes): CompanyState {
    switch (action.type) {
        case RESET_COMPANY:
            return {
                ...initialState,
            };
        case SET_COMPANY:
            return {
                ...state,
                logged_in: true,
                ...action.payload.companyData,
            };
        case LOGOUT_COMPANY:
            return {
                ...state,
                logged_in: false,
            };
        case UPDATE_COMPANY:
            return {
                ...state,
                ...action.payload.companyData,
            };
        default:
            return state;
    }
}
