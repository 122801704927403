import React, { useEffect } from "react"
import {
	BrowserRouter,
	HashRouter,
	Redirect,
	Route,
	Switch,
} from "react-router-dom"
import { connect } from "react-redux"
import { RouteComponentProps } from "react-router-dom"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./App.scss"
import "./loading.css"
import { RootState } from "./reducers"
import Login from "./views/Pages/Login"

const createBrowserHistory = require("history").createBrowserHistory
// export const history = createBrowserHistory();

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"))

// const Login =  React.lazy(() => import("./views/Pages/Login"));
// const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"))
const Page500 = React.lazy(() => import("./views/Pages/Page500"))

toast.configure()

interface AppProps extends RouteComponentProps {
	logged_in?: boolean
	loading?: boolean
}

const App = (props: AppProps) => {
	const { logged_in, loading } = props

	useEffect(() => {
		// getAllCustomers();
		// getAllMaterials();
		// getAllOrders();
	}, [])

	return (
		<HashRouter>
			<React.Suspense
				fallback={
					<div className="animated fadeIn pt-3 text-center">Loading...</div>
				}
			>
				<BrowserRouter basename="/">
					<Switch>
						<Route
							exact
							path="/login"
							render={(props) =>
								!logged_in ? <Login {...props} /> : <Redirect to="/" />
							}
						/>
						{/*<Route*/}
						{/*    exact*/}
						{/*    path="/register"*/}
						{/*    render={(props) => <Register {...props} />}*/}
						{/*/>*/}

						<Route
							exact
							path="/404"
							render={(props) => <Page404 {...props} />}
						/>
						<Route
							exact
							path="/500"
							render={(props) => <Page500 {...props} />}
						/>
						<Route
							path="/"
							render={(props) =>
								logged_in ?
									<DefaultLayout {...props} />
								:	<Redirect to="/login" />
							}
						/>
					</Switch>
				</BrowserRouter>
				{loading ?
					<div
						style={{
							backgroundColor: "#00000051",
							position: "fixed",
							top: 0,
							bottom: 0,
							left: 0,
							right: 0,
							width: "100%",
							height: "100%",
							zIndex: 2,
							textAlign: "center",
						}}
					>
						<div className="cssload-container">
							<div className="cssload-progress cssload-float cssload-shadow">
								<div className="cssload-progress-item" />
							</div>
						</div>
						{/* <div classNameName={"cssload-box-loading"}></div> */}
						{/* <img style={{ margin: 0, marginTop: "15%" }} src={loadingGif} /> */}
					</div>
				:	null}
			</React.Suspense>
		</HashRouter>
	)
}

const mapStateToProps = (state: RootState, ownProps: AppProps) => ({
	logged_in: state.Company.logged_in,
	loading: state.AppData.loading,
})

export default connect(mapStateToProps)(App)
